export const MoCCurrent12_1 = [
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 8.19,
    rank: 1,
    avg_round: 8.95,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 4.73,
    rank: 2,
    avg_round: 6.58,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 4.67,
    rank: 3,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 3.96,
    rank: 4,
    avg_round: 9.61,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 3.84,
    rank: 5,
    avg_round: 9.48,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 3.27,
    rank: 6,
    avg_round: 9.37,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 3.0,
    rank: 7,
    avg_round: 7.74,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 2.34,
    rank: 8,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 2.29,
    rank: 9,
    avg_round: 8.2,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 2.08,
    rank: 10,
    avg_round: 8.53,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 1.59,
    rank: 11,
    avg_round: 4.7,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 1.43,
    rank: 12,
    avg_round: 8.29,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 1.01,
    rank: 13,
    avg_round: 8.36,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 1.0,
    rank: 14,
    avg_round: 8.79,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.83,
    rank: 15,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.81,
    rank: 16,
    avg_round: 8.32,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.79,
    rank: 17,
    avg_round: 7.55,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.74,
    rank: 18,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.7,
    rank: 19,
    avg_round: 8.58,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.64,
    rank: 20,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.62,
    rank: 21,
    avg_round: 9.05,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.61,
    rank: 22,
    avg_round: 8.83,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.58,
    rank: 23,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.57,
    rank: 24,
    avg_round: 8.41,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.57,
    rank: 24,
    avg_round: 9.04,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.57,
    rank: 24,
    avg_round: 6.38,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.53,
    rank: 27,
    avg_round: 7.85,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.49,
    rank: 28,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.48,
    rank: 29,
    avg_round: 7.37,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.47,
    rank: 30,
    avg_round: 8.53,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.47,
    rank: 30,
    avg_round: 8.47,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.45,
    rank: 32,
    avg_round: 8.58,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.42,
    rank: 33,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.4,
    rank: 34,
    avg_round: 7.71,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.4,
    rank: 34,
    avg_round: 8.16,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.4,
    rank: 34,
    avg_round: 7.11,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.4,
    rank: 34,
    avg_round: 7.74,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.36,
    rank: 38,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.35,
    rank: 39,
    avg_round: 6.52,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.34,
    rank: 40,
    avg_round: 8.73,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.33,
    rank: 41,
    avg_round: 7.88,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.31,
    rank: 42,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.31,
    rank: 42,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.29,
    rank: 44,
    avg_round: 8.23,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.29,
    rank: 44,
    avg_round: 7.86,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.28,
    rank: 46,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.28,
    rank: 46,
    avg_round: 8.71,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.27,
    rank: 48,
    avg_round: 9.45,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.27,
    rank: 48,
    avg_round: 9.38,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.27,
    rank: 48,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.27,
    rank: 48,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.26,
    rank: 52,
    avg_round: 8.53,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.26,
    rank: 52,
    avg_round: 8.44,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.25,
    rank: 54,
    avg_round: 8.35,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.25,
    rank: 54,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 54,
    avg_round: 7.66,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 54,
    avg_round: 7.96,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 54,
    avg_round: 7.14,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.24,
    rank: 59,
    avg_round: 8.44,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.24,
    rank: 59,
    avg_round: 8.38,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.23,
    rank: 61,
    avg_round: 5.93,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.23,
    rank: 61,
    avg_round: 6.77,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.23,
    rank: 61,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.23,
    rank: 61,
    avg_round: 1.83,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.22,
    rank: 65,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.22,
    rank: 65,
    avg_round: 7.92,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.21,
    rank: 67,
    avg_round: 6.56,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.21,
    rank: 67,
    avg_round: 9.17,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.2,
    rank: 69,
    avg_round: 8.73,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.2,
    rank: 69,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 69,
    avg_round: 9.64,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 69,
    avg_round: 8.47,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.19,
    rank: 73,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.19,
    rank: 73,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.18,
    rank: 75,
    avg_round: 8.17,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.18,
    rank: 75,
    avg_round: 8.38,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 75,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.18,
    rank: 75,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.18,
    rank: 75,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.18,
    rank: 75,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.18,
    rank: 75,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.17,
    rank: 82,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.17,
    rank: 82,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.17,
    rank: 82,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.17,
    rank: 82,
    avg_round: 8.18,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.16,
    rank: 86,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 86,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.16,
    rank: 86,
    avg_round: 8.82,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 86,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.16,
    rank: 86,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.16,
    rank: 86,
    avg_round: 7.9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.16,
    rank: 86,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.16,
    rank: 86,
    avg_round: 8.44,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.16,
    rank: 86,
    avg_round: 5.89,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.16,
    rank: 86,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 86,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 86,
    avg_round: 6.94,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 98,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.15,
    rank: 98,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 98,
    avg_round: 7.56,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 98,
    avg_round: 9.57,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 98,
    avg_round: 7.45,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 103,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.14,
    rank: 103,
    avg_round: 7.57,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.14,
    rank: 103,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 103,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.14,
    rank: 103,
    avg_round: 8.94,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 103,
    avg_round: 8.09,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 103,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.14,
    rank: 103,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 103,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 103,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 103,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.14,
    rank: 103,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.14,
    rank: 103,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 103,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 117,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.13,
    rank: 117,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.13,
    rank: 117,
    avg_round: 7.11,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 117,
    avg_round: 6.14,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 117,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 122,
    avg_round: 8.27,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 122,
    avg_round: 8.38,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 122,
    avg_round: 5.83,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 122,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 122,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 122,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 122,
    avg_round: 7.44,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 122,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.12,
    rank: 122,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 122,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.12,
    rank: 122,
    avg_round: 8.43,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.12,
    rank: 122,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 122,
    avg_round: 8.73,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 122,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 136,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 136,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 136,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.11,
    rank: 136,
    avg_round: 8.07,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.11,
    rank: 136,
    avg_round: 9.08,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 136,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 136,
    avg_round: 7.12,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 143,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.1,
    rank: 143,
    avg_round: 3.86,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 143,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 143,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.1,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 143,
    avg_round: 6.17,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 143,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 143,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 143,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 143,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.1,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.1,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 143,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.1,
    rank: 143,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 159,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 159,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 159,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 159,
    avg_round: 8.43,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 159,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 159,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.09,
    rank: 159,
    avg_round: 3.33,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 166,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 166,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 7.4,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 166,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 166,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 166,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 166,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 166,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 166,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 166,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 166,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.08,
    rank: 166,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 166,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 166,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 166,
    avg_round: 4.4,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.08,
    rank: 166,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 166,
    avg_round: 7.89,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 166,
    avg_round: 7.89,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 166,
    avg_round: 6.57,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 166,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.08,
    rank: 166,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 166,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 166,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 197,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.07,
    rank: 197,
    avg_round: 4.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.07,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 197,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 197,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.07,
    rank: 197,
    avg_round: 9.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 197,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 197,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 197,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.07,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 197,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 197,
    avg_round: 9.6,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 197,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 197,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 197,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 197,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 216,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 216,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 216,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 216,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 216,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 216,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 216,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 216,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 216,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 216,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 216,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 216,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 216,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 216,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'tingyun-fugue',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 216,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 216,
    avg_round: 3.75,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 216,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 216,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 216,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 235,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 235,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 5.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 235,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 235,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 235,
    avg_round: 7.2,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 235,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 235,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 235,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 235,
    avg_round: 4.75,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 235,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 8.2,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9.17,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 235,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 235,
    avg_round: 7.83,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 235,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 235,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 235,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 235,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 282,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 282,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 282,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 282,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 282,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 282,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 282,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 282,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 282,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 282,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.04,
    rank: 282,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 282,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 282,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 282,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 282,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 282,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 282,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 282,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 282,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 282,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 282,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 282,
    avg_round: 4.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 282,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 282,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 282,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 282,
    avg_round: 4.67,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 282,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 282,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 282,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 282,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 282,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 282,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 314,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lynx',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 314,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 314,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 314,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 314,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'welt',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 314,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 314,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 314,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 416,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'silver-wolf',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 416,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 416,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 416,
    avg_round: 3.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 416,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'welt',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 416,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 416,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 416,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 416,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 416,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 416,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'jiaoqiu',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'welt',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'dr-ratio',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'bronya',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'march-7th',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'pela',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'welt',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'boothill',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 533,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'ruan-mei',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'welt',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'jiaoqiu',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'dr-ratio',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 533,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'welt',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'silver-wolf',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'bronya',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 533,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 533,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 533,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 533,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 533,
    avg_round: 7,
    star_num: '4'
  }
];

export const MoCCurrent12_2 = [
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 5.21,
    rank: 1,
    avg_round: 7.3,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 2.95,
    rank: 2,
    avg_round: 9.32,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 2.88,
    rank: 3,
    avg_round: 8.76,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 2.07,
    rank: 4,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 1.7,
    rank: 5,
    avg_round: 7.98,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 1.63,
    rank: 6,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 1.48,
    rank: 7,
    avg_round: 8.48,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 1.47,
    rank: 8,
    avg_round: 9.6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 1.46,
    rank: 9,
    avg_round: 8.78,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 1.41,
    rank: 10,
    avg_round: 8.59,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 1.21,
    rank: 11,
    avg_round: 8.93,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 1.17,
    rank: 12,
    avg_round: 9.48,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 1.06,
    rank: 13,
    avg_round: 8.01,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 1.05,
    rank: 14,
    avg_round: 7.17,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 1.05,
    rank: 14,
    avg_round: 5.75,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.99,
    rank: 16,
    avg_round: 7.97,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.93,
    rank: 17,
    avg_round: 8.57,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.92,
    rank: 18,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.92,
    rank: 18,
    avg_round: 7.85,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.91,
    rank: 20,
    avg_round: 7.41,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.89,
    rank: 21,
    avg_round: 7.82,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.88,
    rank: 22,
    avg_round: 7.52,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.86,
    rank: 23,
    avg_round: 8.04,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.84,
    rank: 24,
    avg_round: 8.73,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.83,
    rank: 25,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.81,
    rank: 26,
    avg_round: 7.7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.81,
    rank: 26,
    avg_round: 8.78,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.77,
    rank: 28,
    avg_round: 7.89,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.76,
    rank: 29,
    avg_round: 7.71,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.75,
    rank: 30,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.75,
    rank: 30,
    avg_round: 7.98,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.73,
    rank: 32,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.73,
    rank: 32,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.7,
    rank: 34,
    avg_round: 6.62,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.68,
    rank: 35,
    avg_round: 9.44,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.67,
    rank: 36,
    avg_round: 7.66,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.66,
    rank: 37,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.64,
    rank: 38,
    avg_round: 8.17,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.64,
    rank: 38,
    avg_round: 6.69,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.61,
    rank: 40,
    avg_round: 6.98,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.59,
    rank: 41,
    avg_round: 2.18,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.57,
    rank: 42,
    avg_round: 6.46,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.57,
    rank: 42,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.57,
    rank: 42,
    avg_round: 8.64,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.55,
    rank: 45,
    avg_round: 8.32,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.55,
    rank: 45,
    avg_round: 9.2,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.55,
    rank: 45,
    avg_round: 8.86,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.53,
    rank: 48,
    avg_round: 7.49,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.53,
    rank: 48,
    avg_round: 7.13,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.51,
    rank: 50,
    avg_round: 6.63,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.51,
    rank: 50,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.51,
    rank: 50,
    avg_round: 8.17,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.47,
    rank: 53,
    avg_round: 7.3,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.47,
    rank: 53,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.44,
    rank: 55,
    avg_round: 5.31,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.44,
    rank: 55,
    avg_round: 8.07,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.43,
    rank: 57,
    avg_round: 6.64,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.43,
    rank: 57,
    avg_round: 8.63,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.42,
    rank: 59,
    avg_round: 7.42,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.42,
    rank: 59,
    avg_round: 9.2,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.41,
    rank: 61,
    avg_round: 3.17,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.41,
    rank: 61,
    avg_round: 3.89,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.4,
    rank: 63,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.4,
    rank: 63,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.4,
    rank: 63,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.39,
    rank: 66,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.38,
    rank: 67,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.38,
    rank: 67,
    avg_round: 8.71,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.38,
    rank: 67,
    avg_round: 8.79,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.36,
    rank: 70,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.36,
    rank: 70,
    avg_round: 7.54,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.35,
    rank: 72,
    avg_round: 9.36,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.35,
    rank: 72,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.34,
    rank: 74,
    avg_round: 4.47,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.34,
    rank: 74,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.33,
    rank: 76,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.32,
    rank: 77,
    avg_round: 6.43,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.32,
    rank: 77,
    avg_round: 8.15,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.31,
    rank: 79,
    avg_round: 8.08,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.31,
    rank: 79,
    avg_round: 8.79,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.3,
    rank: 81,
    avg_round: 9.09,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.29,
    rank: 82,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.29,
    rank: 82,
    avg_round: 7.54,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.29,
    rank: 82,
    avg_round: 7.82,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.28,
    rank: 85,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.28,
    rank: 85,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.26,
    rank: 87,
    avg_round: 7.79,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.26,
    rank: 87,
    avg_round: 7.96,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 89,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.24,
    rank: 90,
    avg_round: 9.05,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 91,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 91,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 91,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.22,
    rank: 94,
    avg_round: 6.64,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 94,
    avg_round: 8.69,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.21,
    rank: 96,
    avg_round: 7.3,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.21,
    rank: 96,
    avg_round: 8.38,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 98,
    avg_round: 8.7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.2,
    rank: 98,
    avg_round: 7.53,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.2,
    rank: 98,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 98,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.19,
    rank: 102,
    avg_round: 5.2,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 102,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 102,
    avg_round: 8.14,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 102,
    avg_round: 7.57,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 102,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.18,
    rank: 107,
    avg_round: 8.09,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.18,
    rank: 107,
    avg_round: 7.55,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.18,
    rank: 107,
    avg_round: 8.86,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.18,
    rank: 107,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 111,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.16,
    rank: 112,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.16,
    rank: 112,
    avg_round: 3.64,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 112,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.16,
    rank: 112,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 112,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 112,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 112,
    avg_round: 6.82,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.16,
    rank: 112,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 112,
    avg_round: 8.9,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 112,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 122,
    avg_round: 8.55,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 122,
    avg_round: 9.29,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 122,
    avg_round: 9.17,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 125,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.14,
    rank: 125,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 125,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 125,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 125,
    avg_round: 7.9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 125,
    avg_round: 6.4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 125,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.13,
    rank: 132,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 132,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.13,
    rank: 132,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.13,
    rank: 132,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 132,
    avg_round: 8.71,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.13,
    rank: 132,
    avg_round: 6.9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 138,
    avg_round: 7.3,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 138,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.12,
    rank: 138,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 138,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 138,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 138,
    avg_round: 7.71,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 138,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 138,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 138,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.12,
    rank: 138,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 138,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 138,
    avg_round: 9.2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 138,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 138,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 152,
    avg_round: 7.83,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 152,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.11,
    rank: 152,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 152,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 152,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 152,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 152,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.11,
    rank: 152,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 160,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 160,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 160,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 160,
    avg_round: 5.4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 160,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.1,
    rank: 160,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 160,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 160,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 160,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 160,
    avg_round: 5.75,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.1,
    rank: 160,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 160,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 160,
    avg_round: 7.38,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 160,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 160,
    avg_round: 5.33,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 160,
    avg_round: 7.2,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 160,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.1,
    rank: 160,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.1,
    rank: 160,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 160,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 160,
    avg_round: 7.4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 181,
    avg_round: 8.71,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.09,
    rank: 181,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 181,
    avg_round: 6.8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 181,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 181,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 181,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 181,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 181,
    avg_round: 7.71,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.09,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.09,
    rank: 181,
    avg_round: 1.88,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 191,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 191,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 191,
    avg_round: 8.43,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 191,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 191,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 191,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 191,
    avg_round: 7.88,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 191,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 191,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 191,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 191,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.08,
    rank: 191,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 191,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 191,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 191,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 191,
    avg_round: 7.62,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 191,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 191,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.08,
    rank: 191,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 191,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 191,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 191,
    avg_round: 8.17,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 213,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 213,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 213,
    avg_round: 9.4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 213,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 213,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 213,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 213,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 213,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.07,
    rank: 213,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.07,
    rank: 213,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 213,
    avg_round: 3.2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 213,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 213,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.07,
    rank: 213,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'pela',
    app_rate: 0.07,
    rank: 213,
    avg_round: 0.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 213,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 213,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 230,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 230,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 230,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 230,
    avg_round: 6.83,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 230,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 230,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 230,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.06,
    rank: 230,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 230,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 230,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 230,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 230,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 8.2,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.05,
    rank: 242,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 8.71,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 5.75,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 284,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 284,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 284,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 284,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 284,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 284,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 284,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 284,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 284,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 284,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 284,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 284,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 284,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 284,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 284,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 284,
    avg_round: 9.2,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.04,
    rank: 284,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 284,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 284,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 284,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 284,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'pela',
    app_rate: 0.03,
    rank: 305,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 305,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 305,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 305,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 305,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lynx',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 305,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 305,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 305,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 305,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 395,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'pela',
    app_rate: 0.02,
    rank: 395,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 395,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 395,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 395,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'pela',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'huohuo',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 395,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 395,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 395,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 395,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 395,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 395,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 395,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'march-7th',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'qingque',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'moze',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'march-7th',
    app_rate: 0.01,
    rank: 486,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'trailblazer-remembrance',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'qingque',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-remembrance',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'aglaea',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-remembrance',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 486,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'bronya',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 486,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 486,
    avg_round: 10,
    star_num: '4'
  }
];

export const HSRMocHisto = [
  {
    slug: 'acheron',
    score: 8.7,
    usage: 41.5,
    new: false,
    role: 'dps'
  },
  {
    slug: 'aglaea',
    score: 7.46,
    usage: 12.25,
    new: false,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 8.48,
    usage: 1.11,
    new: false,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 11,
    usage: 0.02,
    new: false,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 10.63,
    usage: 0.28,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 8.5,
    usage: 52.55,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 8.92,
    usage: 0.89,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 9.18,
    usage: 15.55,
    new: false,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 9.39,
    usage: 0.91,
    new: false,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 8.91,
    usage: 1.51,
    new: false,
    role: 'dps'
  },
  {
    slug: 'bronya',
    score: 8.69,
    usage: 6.0,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 9,
    usage: 0.56,
    new: false,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 11,
    usage: 0.02,
    new: false,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 9.07,
    usage: 1.57,
    new: false,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 8.46,
    usage: 24.85,
    new: false,
    role: 'dps'
  },
  {
    slug: 'firefly',
    score: 8.94,
    usage: 44.99,
    new: false,
    role: 'dps'
  },
  {
    slug: 'fu-xuan',
    score: 8.62,
    usage: 22.91,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 8.68,
    usage: 39.06,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 9.19,
    usage: 1.37,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 9.86,
    usage: 0.67,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 11,
    usage: 0.1,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 9.33,
    usage: 14.93,
    new: false,
    role: 'specialist'
  },
  {
    slug: 'himeko',
    score: 8.98,
    usage: 2.03,
    new: false,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 11,
    usage: 0.02,
    new: false,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 8.31,
    usage: 22.97,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 9.52,
    usage: 4.18,
    new: false,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 8.22,
    usage: 12.97,
    new: false,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 8.24,
    usage: 23.53,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'jing-yuan',
    score: 9.1,
    usage: 6.8,
    new: false,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 9.57,
    usage: 1.77,
    new: false,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 9.1,
    usage: 12.82,
    new: false,
    role: 'dps'
  },
  {
    slug: 'lingsha',
    score: 8.13,
    usage: 37.05,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'luka',
    score: 11,
    usage: 0.0,
    new: false,
    role: 'dps'
  },
  {
    slug: 'luocha',
    score: 8.83,
    usage: 9.58,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 8.81,
    usage: 0.42,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 11,
    usage: 0.12,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 8.57,
    usage: 10.09,
    new: false,
    role: 'specialist'
  },
  {
    slug: 'misha',
    score: 11,
    usage: 0.02,
    new: false,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 8.6,
    usage: 2.89,
    new: false,
    role: 'specialist'
  },
  {
    slug: 'mydei',
    score: 7.3,
    usage: 4.29,
    new: true,
    role: 'dps'
  },
  {
    slug: 'natasha',
    score: 11,
    usage: 0.04,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 8.59,
    usage: 17.4,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 9.53,
    usage: 0.57,
    new: false,
    role: 'dps'
  },
  {
    slug: 'rappa',
    score: 8.15,
    usage: 19.32,
    new: false,
    role: 'dps'
  },
  {
    slug: 'robin',
    score: 8.24,
    usage: 52.24,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 8.64,
    usage: 71.1,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 10.14,
    usage: 0.4,
    new: false,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 11,
    usage: 0.4,
    new: false,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 8.85,
    usage: 5.91,
    new: false,
    role: 'specialist'
  },
  {
    slug: 'silver-wolf',
    score: 8.98,
    usage: 6.52,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 8.98,
    usage: 20.42,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'sunday',
    score: 8.31,
    usage: 41.02,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'sushang',
    score: 11,
    usage: 0.01,
    new: false,
    role: 'dps'
  },
  {
    slug: 'the-herta',
    score: 8.2,
    usage: 48.25,
    new: false,
    role: 'dps'
  },
  {
    slug: 'tingyun',
    score: 9.22,
    usage: 3.11,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'tingyun-fugue',
    score: 8.54,
    usage: 38.78,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 8.3,
    usage: 12.91,
    new: false,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-destruction',
    score: 11,
    usage: 0.01,
    new: false,
    role: 'dps'
  },
  {
    slug: 'trailblazer-harmony',
    score: 8.7,
    usage: 47.3,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-preservation',
    score: 11,
    usage: 0.0,
    new: false,
    role: 'sustain'
  },
  {
    slug: 'trailblazer-remembrance',
    score: 8.01,
    usage: 29.38,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'tribbie',
    score: 6.77,
    usage: 25.91,
    new: true,
    role: 'specialist'
  },
  {
    slug: 'welt',
    score: 9.88,
    usage: 0.37,
    new: false,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 11,
    usage: 0.1,
    new: false,
    role: 'dps'
  },
  {
    slug: 'yanqing',
    score: 11,
    usage: 0.03,
    new: false,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 11,
    usage: 0.02,
    new: false,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 8.82,
    usage: 2.69,
    new: false,
    role: 'dps'
  }
];

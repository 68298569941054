export const MoCCurrent10_2 = [
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 17.01,
    rank: 1,
    avg_round: 5.04,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 9.66,
    rank: 2,
    avg_round: 5.75,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 9.11,
    rank: 3,
    avg_round: 6.56,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 3.91,
    rank: 4,
    avg_round: 7.13,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 3.56,
    rank: 5,
    avg_round: 7.68,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 3.0,
    rank: 6,
    avg_round: 7.59,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 2.37,
    rank: 7,
    avg_round: 5.46,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 1.64,
    rank: 8,
    avg_round: 6.43,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 1.57,
    rank: 9,
    avg_round: 6.31,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 1.43,
    rank: 10,
    avg_round: 6.25,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 1.16,
    rank: 11,
    avg_round: 6.03,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 1.01,
    rank: 12,
    avg_round: 7.47,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.99,
    rank: 13,
    avg_round: 6.42,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.83,
    rank: 14,
    avg_round: 6.72,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.7,
    rank: 15,
    avg_round: 6.7,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.65,
    rank: 16,
    avg_round: 7.22,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.6,
    rank: 17,
    avg_round: 5.93,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.6,
    rank: 17,
    avg_round: 6.82,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.52,
    rank: 19,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.51,
    rank: 20,
    avg_round: 6.24,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.51,
    rank: 20,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.5,
    rank: 22,
    avg_round: 4.75,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.47,
    rank: 23,
    avg_round: 5.29,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.44,
    rank: 24,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.44,
    rank: 24,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.4,
    rank: 26,
    avg_round: 5.83,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.36,
    rank: 27,
    avg_round: 6.96,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.36,
    rank: 27,
    avg_round: 7.38,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.35,
    rank: 29,
    avg_round: 5.58,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.34,
    rank: 30,
    avg_round: 5.69,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.34,
    rank: 30,
    avg_round: 6.81,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.33,
    rank: 32,
    avg_round: 6.17,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.31,
    rank: 33,
    avg_round: 4.7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.31,
    rank: 33,
    avg_round: 5.68,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.31,
    rank: 33,
    avg_round: 7.19,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.28,
    rank: 36,
    avg_round: 6.73,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.28,
    rank: 36,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'moze',
    char_four: 'aventurine',
    app_rate: 0.27,
    rank: 38,
    avg_round: 7.97,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.27,
    rank: 38,
    avg_round: 7.06,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.26,
    rank: 40,
    avg_round: 5.83,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 41,
    avg_round: 5.88,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.25,
    rank: 41,
    avg_round: 7.62,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.24,
    rank: 43,
    avg_round: 5.64,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.24,
    rank: 43,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.24,
    rank: 43,
    avg_round: 7.29,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 46,
    avg_round: 4.6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.22,
    rank: 47,
    avg_round: 6.9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.22,
    rank: 47,
    avg_round: 1.83,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.21,
    rank: 49,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.21,
    rank: 49,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 51,
    avg_round: 5.09,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.2,
    rank: 51,
    avg_round: 5.93,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 51,
    avg_round: 6.8,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 51,
    avg_round: 6.64,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.19,
    rank: 55,
    avg_round: 6.47,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.19,
    rank: 55,
    avg_round: 7.29,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.18,
    rank: 57,
    avg_round: 6.23,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 57,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.16,
    rank: 59,
    avg_round: 6.31,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 59,
    avg_round: 5.64,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.16,
    rank: 59,
    avg_round: 5.58,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 59,
    avg_round: 7.27,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.16,
    rank: 59,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 59,
    avg_round: 7.86,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 59,
    avg_round: 7.62,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 59,
    avg_round: 7.86,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 67,
    avg_round: 5.78,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 68,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 68,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 68,
    avg_round: 5.18,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 68,
    avg_round: 4.91,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 68,
    avg_round: 5.88,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.14,
    rank: 68,
    avg_round: 7.57,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.14,
    rank: 68,
    avg_round: 6.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 68,
    avg_round: 8.21,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.14,
    rank: 68,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 68,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 78,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 78,
    avg_round: 7.62,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 78,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 78,
    avg_round: 5.6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 78,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 78,
    avg_round: 5.17,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 84,
    avg_round: 7.38,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 84,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 84,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 84,
    avg_round: 8.14,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 84,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 89,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 89,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 89,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 89,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 89,
    avg_round: 5.43,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 89,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.1,
    rank: 95,
    avg_round: 6.92,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 95,
    avg_round: 4.0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 95,
    avg_round: 4.86,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 95,
    avg_round: 5.2,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 95,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.1,
    rank: 95,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 95,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 95,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.1,
    rank: 95,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 95,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 105,
    avg_round: 6.64,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 105,
    avg_round: 6.6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 105,
    avg_round: 7.11,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 105,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 105,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 110,
    avg_round: 6.8,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 110,
    avg_round: 6.6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 110,
    avg_round: 6.4,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 110,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 110,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 110,
    avg_round: 4.75,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 110,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 110,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 110,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 110,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 110,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 110,
    avg_round: 6.8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 110,
    avg_round: 6.44,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 110,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 110,
    avg_round: 6.22,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 110,
    avg_round: 6.12,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 110,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 110,
    avg_round: 4.83,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 110,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 129,
    avg_round: 5.86,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 129,
    avg_round: 6.0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 129,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 129,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 129,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 129,
    avg_round: 4.2,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 129,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 129,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 129,
    avg_round: 5.4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 129,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 129,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 129,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 141,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 6.4,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 141,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 141,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 141,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 141,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 141,
    avg_round: 4.75,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 141,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 141,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.83,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 154,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 5.0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 154,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 154,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 154,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 154,
    avg_round: 4.6,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 154,
    avg_round: 4.33,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 154,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.83,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 154,
    avg_round: 4.4,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 154,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 4.6,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 154,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 154,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 154,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 154,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 154,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 154,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 154,
    avg_round: 8.83,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 154,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 7.83,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.83,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6.4,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 154,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 154,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 203,
    avg_round: 5.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.04,
    rank: 203,
    avg_round: 6.0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 203,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 203,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 203,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lynx',
    app_rate: 0.04,
    rank: 203,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 6.2,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 203,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 4.33,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 5.8,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 203,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 203,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.04,
    rank: 203,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 203,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 203,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 203,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 203,
    avg_round: 4.2,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 5.0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 203,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 203,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 243,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'lynx',
    app_rate: 0.03,
    rank: 243,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'lynx',
    app_rate: 0.03,
    rank: 243,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 243,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 5.25,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 4.0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 243,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 4.75,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 243,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 4.67,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 243,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 243,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 243,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 243,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 243,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 243,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'dr-ratio',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'moze',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'march-7th-swordmaster',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'dr-ratio',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sampo',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'moze',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'sampo',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 335,
    avg_round: 5.33,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 4.67,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 4.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 335,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 335,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 335,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'march-7th-swordmaster',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 11.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 453,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'jiaoqiu',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'asta',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'moze',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'topaz',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'yunli',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'moze',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'march-7th-swordmaster',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'natasha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'asta',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'topaz',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'feixiao',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'march-7th-swordmaster',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'moze',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'aglaea',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'moze',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'blade',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'yunli',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'aventurine',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'sampo',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'sampo',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'bronya',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 453,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'topaz',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 453,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'ruan-mei',
    char_three: 'gallagher',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 453,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 453,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 453,
    avg_round: 7.5,
    star_num: '4'
  }
];
